//slider

$('.slider').slick({
 slidesToShow: 1,
 slidesToScroll: 1,
 arrows: false,
 dots: true,
 autoplay: true,
 autoplaySpeed: 6000,
 mobileFirst:true,
 responsive: [
    {
      breakpoint: 800,
      settings: {
        slidesToShow: 2
      }
    },
  ]
});
// menu onepage
$(document).ready(function(){
$('#menu ul li:nth-child(1)').addClass('active');
$('.nav ul li:nth-child(1)').addClass('active');
        $('section').waypoint(function(direction) {
            //var activeSection = $(this).next();
            var activeSection = $(this.element);
            if(direction === 'down'){
                activeSection = $(this.element).next();
            }
            //activeSection = $(this);
            var sectionId  = activeSection.data('nr');
            $('#menu ul li').removeClass('active');
            $('#menu ul li:nth-child(' + sectionId+')').addClass('active');
            $('.nav ul li').removeClass('active');
            $('.nav ul li:nth-child(' + sectionId+')').addClass('active');
            //console.log(activeSection);
        });
});
$('a[href*=\\#]:not([href=\\#])').click(function() {
    if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'')
        || location.hostname == this.hostname) {
        var target = $(this.hash);
        target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
           if (target.length) {
             $('html,body').animate({
                 scrollTop: target.offset().top - 180
            }, 500);
            return false;
        }
    }
});
// menu onepage


$(window).scroll(function(){
  var scroll = $(window).scrollTop();
  if (scroll > 1){
    $('.top').addClass('active');
  }else{
    $('.top').removeClass('active');
  }
});

    $(".burger-menu").click(function () {
      $(this).toggleClass('actives');
      $(".mobile-menu").toggleClass('active');
    });
    $(".menu-item a").click(function () {
         $(".mobile-menu").removeClass('active');
         $(".burger-menu").removeClass('actives');
    });




// Set cookies onload
function WHCreateCookie(c, d, e) {
    var b = new Date();
    b.setTime(b.getTime() + (e * 24 * 60 * 60 * 1000));
    var a = "; expires=" + b.toGMTString();
    document.cookie = c + "=" + d + a + "; path=/";
}
function WHReadCookie(b) {
    var e = b + "=";
    var a = document.cookie.split(";");
    for (var d = 0; d < a.length; d++) {
        var f = a[d];
        while (f.charAt(0) == " ") {
            f = f.substring(1, f.length);
        }
        if (f.indexOf(e) == 0) {
            return f.substring(e.length, f.length);
        }
    }
    return null;
}
window.onload = WHCheckCookies;
function WHCheckCookies() {
    if (WHReadCookie("cookies_accepted") !== "T") {
        $('#cookies-message').fadeToggle();
    }
}
function WHCloseCookiesWindow() {
    WHCreateCookie("cookies_accepted", "T", 365);
    $("#cookies-message").hide();
}
// Set cookis onload
